<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-5">
            <div class="col-md-7 text-center heading-section ftco-animate">
                <span>Portfolio</span>
                <h2>Recent projects</h2>
            </div>
        </div>
        <div class="row no-gutters">

            <div class="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                <a href="https://realis.network" target="_blank" class="image order-2 d-flex justify-content-center align-items-center" style="background-image: url('images/work-8.jpg'); ">
                    <div class="icon d-flex text-center justify-content-center align-items-center">
                        <span class="icon-external-link"></span>
                    </div>
                </a>
                <div class="text order-1">
                    <h4 class="subheading">#crypto #games #blockchain</h4>
                    <h2 class="heading"><a href="https://realis.network" target="_blank">Realis</a></h2>
                    <p>Realis is the world's first platform for launching mobile games using blockchain technologies for a mass audience based on Substrate in Polkadot ecosystem.</p>
                    <p><a class="project-view-btn" href="https://realis.network" target="_blank">View Project</a></p>
                </div>
            </div>

            <div class="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                <a href="https://buduy-sam.com" target="_blank" rel="nofollow" class="image d-flex justify-content-center align-items-center" style="background-image: url('images/work-9.jpg'); ">
                    <div class="icon d-flex text-center justify-content-center align-items-center">
                        <span class="icon-external-link"></span>
                    </div>
                </a>
                <div class="text">
                    <h4 class="subheading">#design #architecture #planning</h4>
                    <h2 class="heading"><a href="https://buduy-sam.com" target="_blank" rel="nofollow">Buduy Sam</a></h2>
                    <p>Design of all stages of construction, from the foundation to the moment of settling in your home.</p>
                    <p><a class="project-view-btn" href="https://buduy-sam.com" target="_blank" rel="nofollow">View Project</a></p>
                </div>
            </div>

            <div class="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                <a href="https://avtoprokat.rivne.ua" target="_blank" class="image order-2 d-flex justify-content-center align-items-center" style="background-image: url('images/work-10.jpg'); ">
                    <div class="icon d-flex text-center justify-content-center align-items-center">
                        <span class="icon-external-link"></span>
                    </div>
                </a>
                <div class="text order-1">
                    <h4 class="subheading">#cars #rent #rivne</h4>
                    <h2 class="heading"><a href="https://avtoprokat.rivne.ua" target="_blank">AvtoProkat</a></h2>
                    <p>AvtoProkat has been successfully organizing car rental in Rivne for a long time. The company's drivers are ready to meet the city's guests around the clock.</p>
                    <p><a class="project-view-btn" href="https://avtoprokat.rivne.ua" target="_blank">View Project</a></p>
                </div>
            </div>

            <div class="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                <a href="https://t.me/Comparer_bot" target="_blank" rel="nofollow" class="image d-flex justify-content-center align-items-center" style="background-image: url('images/work-4.jpg'); ">
                    <div class="icon d-flex text-center justify-content-center align-items-center">
                        <span class="icon-external-link"></span>
                    </div>
                </a>
                <div class="text">
                    <h4 class="subheading">#telegram #bot #prices</h4>
                    <h2 class="heading"><a href="https://t.me/Comparer_bot" target="_blank" rel="nofollow">Comparer Bot</a></h2>
                    <p>If you are going to travel or you are just interested in comparing prices in two countries or cities, Comparer Bot will help you do this in 2 clicks.</p>
                    <p><a class="project-view-btn" href="https://t.me/Comparer_bot" target="_blank" rel="nofollow">View Project</a></p>
                </div>
            </div>

            <div class="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                <a href="https://bizreis.com" target="_blank" class="image order-2 d-flex justify-content-center align-items-center" style="background-image: url('images/work-12.jpg'); ">
                    <div class="icon d-flex text-center justify-content-center align-items-center">
                        <span class="icon-external-link"></span>
                    </div>
                </a>
                <div class="text order-1">
                    <h4 class="subheading">#cars #sale #transport</h4>
                    <h2 class="heading"><a href="https://bizreis.com" target="_blank">Business flight</a></h2>
                    <p>A company with a long history of passenger transport throughout Europe and the sale of cars of various classes.</p>
                    <p><a class="project-view-btn" href="https://bizreis.com" target="_blank">View Project</a></p>
                </div>
            </div>

            <div class="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                <a href="https://hostel-arena.com" target="_blank" rel="nofollow" class="image d-flex justify-content-center align-items-center" style="background-image: url('images/work-11.jpg'); ">
                    <div class="icon d-flex text-center justify-content-center align-items-center">
                        <span class="icon-external-link"></span>
                    </div>
                </a>
                <div class="text">
                    <h4 class="subheading">#hostel #accommodation #booking</h4>
                    <h2 class="heading"><a href="https://hostel-arena.com" target="_blank" rel="nofollow">Arena Hostel</a></h2>
                    <p>Arena Hostel is a smart choice for travelers to Kovel city, offering a relaxed and hassle-free stay every time.</p>
                    <p><a class="project-view-btn" href="https://hostel-arena.com" target="_blank" rel="nofollow">View Project</a></p>
                </div>
            </div>

        </div>
    </div>
</section>
