<footer class="ftco-footer ftco-bg-dark ftco-section">
    <div class="container">
        <div class="row mb-5 justify-content-center">
            <div class="col-md-12 text-center">
                <div class="ftco-footer-widget mb-5">
                    <ul class="ftco-footer-social list-unstyled">
                        <li class="ftco-animate"><a href="https://t.me/Abmin" target="_blank" rel="nofollow"><span class="icon-telegram"></span></a></li>
                        <li class="ftco-animate"><a href="https://www.linkedin.com/in/abmin/" target="_blank" rel="nofollow"><span class="icon-linkedin"></span></a></li>
                        <li class="ftco-animate"><a href="https://www.facebook.com/Abmin.dev" target="_blank" rel="nofollow"><span class="icon-facebook"></span></a></li>
                        <li class="ftco-animate"><a href="https://www.instagram.com/andrehavryliuk/" target="_blank" rel="nofollow"><span class="icon-instagram"></span></a></li>
                    </ul>
                </div>
                <div class="ftco-footer-widget">
                    <h2 class="mb-3">Contact me</h2>
                    <p class="h3 email"><a href="mailto:andrehavryliuk@gmail.com" target="_blank" rel="nofollow">AndreHavryliuk@gmail.com</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>
