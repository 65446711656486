<script>
	import Header from './components/Header.svelte'
	import Slider from './components/Slider.svelte'
	import About from './components/About.svelte'
	import WhatDo from './components/WhatDo.svelte'
	import Portfolio from './components/Portfolio.svelte'
	import Numbers from './components/Numbers.svelte'
	import Footer from './components/Footer.svelte'
</script>

<main>
	<div class="page">
		<Header/>
		<Slider/>
		<About/>
		<WhatDo/>
		<Portfolio/>
		<Numbers/>
		<Footer/>
	</div>
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>
