<section class="ftco-section ftco-counter" id="section-counter">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-5">
            <div class="col-md-7 text-center heading-section ftco-animate">
                <span>In numbers</span>
                <h2>I love to share my achievements</h2>
            </div>
        </div>
        <div class="row d-flex justify-content-start">
            <div class="col-md-5 col-sm-5 counter-wrap ftco-animate">
                <div class="block-18">
                    <div class="text">
                        <span class="ftco-label">Years of experience</span>
                        <strong class="number" data-number="<?php echo date('Y')-2010; ?>">0</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-5 col-sm-5 counter-wrap ftco-animate">
                <div class="block-18">
                    <div class="text">
                        <span class="ftco-label">Clients</span>
                        <strong class="number" data-number="87">0</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex justify-content-end">
            <div class="col-md-5 counter-wrap ftco-animate">
                <div class="block-18">
                    <div class="text">
                        <span class="ftco-label">Completed projects</span>
                        <strong class="number" data-number="128">0</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
