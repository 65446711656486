<script>
    let years = 10
    let date = 2013
</script>

<section class="ftco-section about-section">
    <div class="container">
        <div class="row d-flex" data-scrollax-parent="true">
            <div class="col-md-4 author-img" style="background-image: url('images/avatar.jpg?2');"></div>
            <div class="col-md-2"></div>
            <div class="col-md-6 wrap ftco-animate">
                <div class="about-desc">
                    <h2 class="bold-text">About</h2>
                    <div class="p-5 wrp-about-text">
                        <h2 class="mb-5">Hi! I'm Andre Havryliuk</h2>
                        <p>I'm {years}> years old. I have been working in web development for {date} years. I took part in creating different startups. I freelanced. I worked remotely. Some time I worked in web studios. And I made several my own projects.</p>
                        <p><a href="#" style="display: none">Checkout my resume</a></p>
                        <ul class="ftco-footer-social list-unstyled mt-4">
                            <li><a class="social-link" href="https://t.me/Abmin" target="_blank" rel="nofollow"><span class="icon-telegram"></span></a></li>
                            <li><a  class="social-link" href="https://www.linkedin.com/in/abmin/" target="_blank" rel="nofollow"><span class="icon-linkedin"></span></a></li>
                            <li><a  class="social-link" href="https://www.facebook.com/Abmin.dev" target="_blank" rel="nofollow"><span class="icon-facebook"></span></a></li>
                            <li><a  class="social-link" href="https://www.instagram.com/andrehavryliuk/" target="_blank" rel="nofollow"><span class="icon-instagram"></span></a></li>
                        </ul>
                        <h5>Contact me here!</h5>
                        <p>Email: <a href="mailto:andrehavryliuk@gmail.com" target="_blank" rel="nofollow">AndreHavryliuk@gmail.com</a></p>
                        <p>Telegram: <a href="https://t.me/Abmin" target="_blank" rel="nofollow">@Abmin</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
