<script lang="ts">
    import avatarThumb from '../components/#assets/mini.jpg'
</script>

<header>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="colorlib-navbar-brand">
                    <a class="colorlib-logo" href="/">
                        <span class="logo-img" style="--AvatarThumb:url({avatarThumb});"></span>Andre Havryliuk
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>

<style>
    .logo-img {
        background: var(--AvatarThumb) no-repeat center bottom;
        background-size: cover;
    }
    header {
        padding: 2em 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        margin: 0 auto;
    }
    @media (max-width: 767.98px) {
        header {
            padding: 2em 0;
            position: absolute; }
    }
    header .colorlib-navbar-brand {
        float: left;
    }
    header .colorlib-navbar-brand .colorlib-logo {
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        font-weight: 400;
        letter-spacing: 5px;
        padding: 7px;
        display: block;
        text-align: center;
        position: relative;
        padding-left: 62px;
    }
    header .colorlib-navbar-brand .colorlib-logo .logo-img {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
    }
    header .colorlib-navbar-brand .colorlib-logo:hover {
        text-decoration: none !important;
    }
    header .colorlib-navbar-brand .colorlib-logo:active,
    header .colorlib-navbar-brand .colorlib-logo:focus {
        outline: none;
        text-decoration: none;
    }
</style>
