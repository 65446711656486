<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-5">
            <div class="col-md-7 text-center heading-section ftco-animate">
                <span>What i do</span>
                <h2>Full Stack</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                <div class="media block-6 services p-3 py-4 d-block text-center">
                    <div class="icon mb-3"><span class="icon-layers"></span></div>
                    <div class="media-body">
                        <h3 class="heading">Design</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                <div class="media block-6 services p-3 py-4 d-block text-center">
                    <div class="icon mb-3"><span class="icon-code"></span></div>
                    <div class="media-body">
                        <h3 class="heading">Frontend</h3>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                <div class="media block-6 services p-3 py-4 d-block text-center">
                    <div class="icon mb-3"><span class="icon-gears"></span></div>
                    <div class="media-body">
                        <h3 class="heading">Backend</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
