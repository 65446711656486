<script>
    import Carousel from 'svelte-carousel'

    let carousel
</script>

<Carousel bind:this={carousel} arrows={false} autoplay={true} dots={false}>
    <div class="slider-item js-fullheight">
        <div class="overlay"></div>
        <div class="container">
            <div class="row slider-text align-items-center text-center justify-content-center" data-scrollax-parent="true">
                <div class="col-md-12">
                    <p>Hello! I'm</p>
                    <h1 class="mb-3">Andre Havryliuk</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item js-fullheight">
        <div class="overlay"></div>
        <div class="container">
            <div class="row slider-text align-items-center text-center justify-content-center" data-scrollax-parent="true">
                <div class="col-md-12">
                    <p>I'm from Ukraine</p>
                    <h2 class="mb-3">A Web Developer</h2>
                </div>
            </div>
        </div>
    </div>
</Carousel>

<style>
    .slider-text {
        color: #fff;
        height: calc(100vh - 117px);
        min-height: 700px;
        position: relative;
    }

    .slider-text p {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 300;
        color: rgba(255,255,255,.8);
    }

    .slider-text h1,
    .slider-text h2 {
        font-size: 55px;
        color: #fff;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 10px;
        display: inline-block;
        position: relative;
    }
</style>
